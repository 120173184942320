
<template>
  <div>
    <Button type="button" label="Quittieren" icon="pi pi-users" class="p-button-warning stickyButton"
      :badge="selectedCounter.toString()" badgeClass="p-badge-info" @click="confirmSelectedAlarms" />
    <div class="card">
      <DataTable 
        :value="alarmList"
        v-model:selection="selectedAlarms"
        dataKey="identifier"
        class="p-datatable-sm"
        responsiveLayout="stack"
        sortMode="single"
        sortField="alarmPrior"
        :sortOrder="-1"
        :filters="filters" 
        :rowClass="rowClass"
        :loading="loading"
        rowGroupMode="subheader"
        groupRowsBy="alarmPrior"
        @rowSelect="onRowSelect($event)"
        @rowUnselect="onRowUnselect"
        @rowSelectAll="onRowSelectAll"
        @rowUnselectAll="onRowUnselectAll"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #empty> aktuell gibt es keine Alarme </template>
        <template #header>
          <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <div>
              <Button
                type="button"
                label="Quittieren"
                icon="pi pi-users"
                severity="secondary"
                :badge="selectedCounter.toString()"
                badgeClass="p-badge-info"
                @click="confirmSelectedAlarms"
                class="mr-2"
              />
              <Button
                type="button"
                label="Aktualisieren"
                icon="pi pi-refresh"
                severity="primary"
                @click="loadAlarms"
              />
            </div>
            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">Aktive und quittierte Alarme</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="suchen..." />
            </span>
          </div>
        </template>
        <template #groupheader="slotProps">
          <template v-if="slotProps.data.alarmPrior === 3">
            <Badge value="PRIORITÄT 3" class="p-badge-plain mr-1 mt-3 bg-lightIndigo fg-yellow"></Badge>
          </template>
          <template v-if="slotProps.data.alarmPrior === 2">
            <Badge value="PRIORITÄT 2" class="p-badge-plain mr-1 mt-3 bg-red fg-yellow"></Badge>
          </template>
          <template v-if="slotProps.data.alarmPrior === 1">
            <Badge value="PRIORITÄT 1" class="p-badge-plain mr-1 mt-3 bg-lightOrange fg-yellow"></Badge>
          </template>
          <template v-if="slotProps.data.alarmPrior === -1">
            <Badge value="QUITTIERTE ALARME" class="p-badge-plain mr-1 mt-3"></Badge>
          </template>
          <!-- <img
            :alt="slotProps.data.kategorie"
            src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
            width="32"
            style="vertical-align: middle"
          /> -->
          <span class="image-text">{{ getAlarmStatusText(slotProps.data) }}</span>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="state.priority" header="Priorität" :sortable="true">
          <template #body="slotProps">
            <div>
              <Rating :modelValue="slotProps.data.alarmPrior" :readonly="true" :cancel="false" :stars="3" />
            </div>
          </template>
        </Column>
        <Column field="state.value" header="Zustand" :sortable="true">
          <template #body="slotProps">
            <i :class="getAlarmStatusIcon(slotProps.data)"></i>
          </template>
        </Column>
        <Column field="timestamp" header="Zeitstempel" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.timestamp) }}
          </template>
        </Column>
        <Column field="department" header="Anlage" :sortable="true"></Column>
        <Column field="category" header="Kategorie" :sortable="true">
          <template #body="slotProps">
            <span>{{ getOneCategory(slotProps.data.category).label }}</span>
          </template>
        </Column>
        <Column field="moduleLabel" header="Modul" :sortable="true"></Column>
        <Column field="alarmLabel" header="Ereignis" :sortable="true"></Column>
        <Column field="alarmDesc" header="Beschreibung" :sortable="true"></Column>
        <Column field="setVal" header="Grenzwert" :sortable="true"></Column>
        <Column field="eventVal" header="Alarmwert" :sortable="true"></Column>
        <template #footer>
          <span>Liste muss manuell aktualisiert werden, wenn der aktuelle Status abgefragt werden möchte</span>
        </template>
      </DataTable>
    </div>
  </div>
  <div style="padding-bottom: 4rem;"></div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import SocketioService from '@/services/socketioService';
import { formatDateLog, setOpcAlarmToast } from '@/helpers';
import { mapGetters, mapActions } from 'vuex';
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";

export default {
  name: "alarmList",
  data() {
    return {
      loading: false,
      alarmList: [],
      products: null,
      selectedProduct1: null,
      selectedProduct2: null,
      selectedProduct3: null,
      selectedProducts1: null,
      selectedProducts2: null,
      selectedAlarms: null,
      filters: {},
      selectedCounter: 0,
      selectedCounter1: "1",
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    // this.$store.dispatch('opcua/loadAlarms');
  },
  mounted() {
    this.loadAlarms();
  },
  computed: {
    ...mapGetters({
        editMode: 'getEditMode',
        getNodeWidget: 'getNodeWidget',
        getOneDepartment: 'types/getOneDepartment',
        getOneCategory: 'types/getOneCategory',
        getStyleColors: 'getStyleColors',
        getStylePatterns: 'getStylePatterns',
        getModules: 'opcua/getModules',
        getModule: 'opcua/getModule',
        getAlarms: 'opcua/getAlarms',
    }),
  },
  methods: {
    ...mapActions({
        loadNode: 'opcua/loadNode',
        loadModule: 'opcua/loadModule',
        setNodesFull: 'opcua/setNodesFull',
    }),
    loadAlarms() {
      this.loading = true;
      SocketioService.getRedisNodesParamScaled(`*!alarmEntry!*`, (err, response) => {
        console.log(response);
        if (err) {
          console.log(`[ENATWIDGET] error fetching params: ${err}`);
        } else {
          if (Array.isArray(response)) {
            console.log(response);
            if (response.length > 0) {
              response.forEach((alarmEntry) => {
                if (typeof alarmEntry.value === 'string') alarmEntry.value = parseInt(alarmEntry.value);
                if (typeof alarmEntry.alarmPrior === 'string') alarmEntry.alarmPrior = parseInt(alarmEntry.alarmPrior);
              });
              this.alarmList = [ ...response ];
            } else {
              this.alarmList = [];
            }
          } else {
            this.alarmList = [];
          }
        }
      }).then(() => {
          this.loading = false;
      }); 
    },
    rowClass(data) {
      if (data.value === -1) return "noPriorityAlarm line-through";
      else if (data.value === 1 && data.alarmPrior === 1) return "lowPriorityAlarm";
      else if (data.value === 1 && data.alarmPrior === 2) return "mediumPriorityAlarm";
      else if (data.value === 1 && data.alarmPrior === 3) return "highPriorityAlarm";
      else return null;
    },
    getAlarmStatus(data) {
      return [
        {
          highPriorityAlarm: (data.value === 1 && data.alarmPrior === 3),
          mediumPriorityAlarm: (data.value === 1 && data.alarmPrior === 2),
          lowPriorityAlarm: (data.value === 1 && data.alarmPrior === 1),
          noPriorityAlarm: (data.value === -1),
        },
      ];
    },
    getAlarmStatusIcon(data) {
      console.log(data);
      if (data.value === -1) return "pi pi-check-circle fg-lightYellow text-lg";
      else if (data.value === 1) return "pi pi-bell fg-lightRed text-lg";
      else return '';
    },
    getAlarmStatusIconBg(data) {
      // if (data.state === -1) return "p-button-raised p-button-rounded p-button-success";
      if (data.value === -1) return "success";
      else if (data.value === 1) return "danger";
      else return '';
    },
    getAlarmStatusText(data) {
      if (data.value === -1) return "Quittiert";
      else if (data.value === 1 && data.alarmPrior === 1) return "Niedrig";
      else if (data.value === 1 && data.alarmPrior === 2) return "Hoch";
      else if (data.value === 1 && data.alarmPrior === 3) return "Akut";
      else return '';
    },
    confirmSelectedAlarms() {
      if (this.selectedAlarms !== null) {
        if (this.selectedAlarms.length > 0) {
          let saveArray = [];
          this.selectedAlarms.forEach((element) => {
            if (element.alarmRoot) {
              const alarmObj = {
                server: element.server,
                identifier: `${element.alarmRoot}.IN_SnoozeAlarm`,
                ns: element.ns,
                value: '1',
                writeable: 'true',
                label: element.label,
                department: element.department
              }
              saveArray.push(alarmObj);
            }
          });

          if (saveArray.length > 0) {
            this.loading = true;
            SocketioService.setOpcValue(saveArray, (err, response) => {
              if (err) {
                console.log(err);
              } else {
                console.log(response);
                let wait = setTimeout(() => {
                  setOpcAlarmToast(response, this.$root);
                  // this.$toast.add({
                  //   severity: 'success',
                  //   summary: 'Erfolgreich quittiert!',
                  //   detail: `${this.selectedAlarms.length} Alarme erfolgreich quittiert`,
                  //   life: 3500,
                  // });
                  this.selectedAlarms = null;
                  this.selectedCounter = 0;
                  this.loadAlarms();
                  clearTimeout(wait);
                }, 3000);
              }
            });
          } else {
            setOpcAlarmToast(null, this.$root);
            // this.$toast.add({
            //   severity: 'warn',
            //   summary: 'Keine Änderungen!',
            //   detail: 'Es wurde kein einziger Alarm quittiert!',
            //   life: 3000,
            // });
          }
        }
      } else {
        setOpcAlarmToast(null, this.$root);
        // this.$toast.add({
        //   severity: "info",
        //   summary: "Nichts ausgewählt",
        //   detail: "Es wurde kein Alarm ausgewählt",
        //   life: 3000,
        // });
      }
    },
    onRowSelect(event) {
      console.log(event);
      this.$toast.add({
        severity: "warn",
        summary: `Alarm ${event.data.alarmLabel} ausgewählt`,
        detail: `${event.data.department} ${event.data.moduleLabel} soll quittiert werden`,
        life: 3000,
      });
      this.selectedCounter += 1;
    },
    onRowUnselect() {
      this.selectedCounter -= 1;
    },
    onRowSelectAll(event) {
      this.$toast.add({
        severity: "warn",
        summary: `Alle Alarme ausgewählt`,
        detail: `Bitte um Kontrolle, ob wirklich alle Alarme quittiert werden sollen!`,
        life: 5000,
      });
      this.selectedCounter = event.data.length;
    },
    onRowUnselectAll() {
      this.selectedCounter = 0;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(timestamp) {
      return formatDateLog(timestamp);
    },
  },
};
</script>    

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mistkind {
  color: pink;
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

::v-deep(.highPriorityAlarm) {
  font-weight: 400 !important;
  background-color: #9b069b80 !important;
}

::v-deep(.mediumPriorityAlarm) {
  font-weight: 400 !important;
  background-color: #ff262680 !important;
}

::v-deep(.lowPriorityAlarm) {
  font-weight: 400 !important;
  background-color: #ff7b0080 !important;
}

::v-deep(.noPriorityAlarm) {
  font-weight: 400 !important;
  background-color: #4b8a33 !important;
}

::v-deep(.row-state) {
  background-color: rgb(0, 180, 9) !important;
  color: black !important;
}

.stickyButton {
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 4;
}
</style>